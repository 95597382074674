import React from 'react';
import CommonCtaArea from '../../components/CommonCtaArea/CommonCtaArea';
import PageHelmet from '../../components/shared/PageHelmet';
import HomeTwoTestimonial from '../HomeTwo/HomeTwoTestimonial/HomeTwoTestimonial';
import HomeThreeFaq from './HomeThreeFaq/HomeThreeFaq';
import HomeThreeFooter from './HomeThreeFooter/HomeThreeFooter';
import HomeThreeHeader from './HomeThreeHeader/HomeThreeHeader';
import HomeThreeHeroSection from './HomeThreeHeroSection/HomeThreeHeroSection';
import HomeThreeProjects from './HomeThreeProjects/HomeThreeProjects';
import HomeThreeSecondServices from './HomeThreeSecondServices/HomeThreeSecondServices';
import HomeThreeServices from './HomeThreeServices/HomeThreeServices';
import HomeBrands from '../Home/HomeBrands/HomeBrands';
import HomeTwoCaseArea from '../HomeTwo/HomeTwoCaseArea/HomeTwoCaseArea';
import HomeTwoExpertArea from '../HomeTwo/HomeTwoExpertArea/HomeTwoExpertArea';
import HomeAbout from '../Home/HomeAbout/HomeAbout';
import HomeTwoBlogs from '../HomeTwo/HomeTwoBlogs/HomeTwoBlogs';
import HomeTwoCounter from '../HomeTwo/HomeTwoCounter/HomeTwoCounter';

const HomeThree = () => {
   return (
      <>
         <PageHelmet pageTitle="Home Three" />

         <HomeThreeHeader />
         <HomeThreeHeroSection />
         <HomeThreeServices />
         {/* <HomeAbout /> */}
         <HomeTwoCaseArea/>
         <HomeThreeSecondServices />
         <HomeTwoExpertArea/>
         <HomeTwoCounter/>
         {/* <HomeTwoTestimonial /> */}
         <HomeThreeProjects />
         <HomeTwoTestimonial/>
         <HomeBrands />
         <HomeTwoBlogs/>
         <HomeThreeFaq />
         <CommonCtaArea />
         <HomeThreeFooter />
      </>
   );
};

export default HomeThree;